const taglines: string[] = [
  'i am a woman who codes',
  'i eat a lot of hot cheetos',
  'i think a lot',
  'i like to think about education',
  'i am always questioning my identity',
  'i enjoy solo travelling',
  'i am interested in inclusive technology',
];

const getTagline = () : string => {
  const max = taglines.length;
  const ind = Math.floor(Math.random() * max);
  return taglines[ind];
};

export default getTagline;
