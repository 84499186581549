import React from 'react';
import './index.css';

interface ExpDescription {
    company: string;
    position: string;
    timeline: string;
    description: string[];
    image: string;
}

export default class ExperienceSubs extends React.Component<{ item: ExpDescription; position: string }, {}> {
    private textBlock = (): JSX.Element => {
        const experience = this.props.item;
        return (
            <div className="textblock">
                <p className="title">{experience.company}, {experience.position}</p>
                <p className="subtext">{experience.timeline}</p>
                <p className="subtext description">
                    {
                        experience.description.map((phr, ind) => {
                            if (phr.indexOf("http") > -1) {
                                const arr = phr.split("||");
                                return <a href={arr[0]} target="_blank">{arr[1]}</a>
                            } else {
                                return phr;
                            }
                        })
                    }
                </p>
            </div>
        )
    }

    private imageBlock = (): JSX.Element => {
        return (
            <img src={this.props.item.image}/>
        )
    }

    public render(): JSX.Element {
        if (this.props.position === "right") {
            return (
                <div className="expSub">
                    {this.textBlock()}
                    {this.imageBlock()}
                </div>
            )
        } else {
            return (
                <div className="expSub">
                    {this.imageBlock()}
                    {this.textBlock()}
                </div>
            )
        }
    }
}