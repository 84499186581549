import React from 'react';
import './index.css';

import experiences from "./experiences";
import getTagline from "./taglines";

import ExperienceSubs from "../ExperienceSubs";

const projects = [
    ["created an online version of my favorite board game, Splendor, during quarantine.  play with your friends ","http://splendor.josieku.io/||here","!"],
    ["enjoy exploring the coasts of Hong Kong.  check out my adventures and reflections on my instagram ", "https://www.instagram.com/aroundwithjojo/||@aroundwithjojo"],
    ["teach coding in my free time"],
    ["built a React Native mobile prototype for an upcoming Wellesley Centers for Women research project aimed at helping youth gain social media awareness"],
]

const social = [
    {
        image: require("./images/Mail.png"),
        link: "mailto:jku@wellesley.edu"
    },
    {
        image: require("./images/Github.png"),
        link: "https://github.com/josieku"
    },
    {
        image: require("./images/LinkedIn.png"),
        link: "https://www.linkedin.com/in/josieku/"
    },
]

export default class Home extends React.Component<{}, { tagline: string }> {
    private experienceDiv = React.createRef<HTMLDivElement>();
    private contactDiv = React.createRef<HTMLDivElement>();

    public state = {
        tagline: "[tagline!]"
    }

    public componentDidMount(): void {
        this.refreshTagline();
        setInterval(()=>this.refreshTagline(), 2000)
    }

    private handleExperienceClick = () => {
        if(this.experienceDiv.current){
            this.experienceDiv.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "start"
            })
        }
    }

    private handleContactClick = () => {
        if(this.contactDiv.current){
            this.contactDiv.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "nearest"
            })
        }
    }

    private refreshTagline = () => {
        let newTag = getTagline();
        const { tagline } = this.state;
        while (newTag === tagline) {
        newTag = getTagline();
        }
        this.setState({ tagline: newTag });
    }

    private renderIntro = (): JSX.Element => {
        return (
            <div className="header">
                <h1>JOSIE KU</h1>
                <div className="menu">
                    <p onClick={this.handleExperienceClick}>experience</p>
                    <p onClick={this.handleContactClick}>contact</p>
                    <a href="/KuJosie_Aug2021.pdf" target="_blank">resume</a>
                </div>
            </div>
        )
    }

    private renderLanding = (): JSX.Element => {
        return (
            <div className="landing">
                <div className="intro-text">
                    <p>hello! </p>
                    <p>i am an adventure seeker,</p>
                    <p>react.js superfan,</p>
                    <span className="line">
                        <p style={{width: "auto"}}>and </p>
                        <img src={require("./images/refresh.png")} onClick={this.refreshTagline}/>
                        <p className="tagline" style={{width: "auto"}}>{this.state.tagline}</p>
                    </span>
                </div>
                <p>
                    i am passionate about the intersection of education and technology. 
                    i have done ed-tech related work at <a href="https://www.wcwonline.org/" target="_blank">Wellesley Centers for Women</a>
                    , <a href="https://www.media.mit.edu/">MIT Media Lab</a>, <a href="https://microsoft.com" target="_blank">Microsoft</a>, and <a href="https://www.firstcodeacademy.com/" target="_blank">First Code Academy</a>.</p>
                <p>
                    i am a senior at <a href="http://wellesley.edu/" target="_blank">Wellesley College</a> where i'm heavily involved with the <a href="https://www.facebook.com/wellesleycsa/" target="_blank">Chinese Student Association</a> and the <a href="http://wellesleywhiptails.com/" target="_blank">Whiptails Ultimate Frisbee Team</a>.</p>
                <p>i took a gap year from 2019-20 (covid extended to 2021!) from school and worked at cool places like <a href="https://bppe.com/" target="_blank">Blackpine</a> and <a href="https://www.airwallex.com/" target="_blank">Airwallex</a>.</p>
            </div>
        )
    }

    private renderExperience = (): JSX.Element => {
        return (
            <div className="experience" ref={this.experienceDiv} tabIndex={0}>
                <p className="subtitle">experience</p>
                {
                    experiences.map((exp, ind) => (
                        <ExperienceSubs 
                            item={exp} 
                            key={`expsub_${ind}`} 
                            position={ind%2===0?"right": "left"}/>
                    ))
                }
            </div>
        )
    }

    private renderProjects = (): JSX.Element => {
        return (
            <div className="projects">
                <p className="subtitle">other interests/projects</p>
                {
                    projects.map((text) => <Bullet text={text}/>)
                }
            </div>
        )
    }

    private renderContact = (): JSX.Element => {
        return (
            <div className="contact" ref={this.contactDiv} tabIndex={0}>
                <p className="subtitle">anyways... drop a line, say hello!</p>
                <p className="subtext">i’m available for freelance or tutoring work but can also talk about anything.  i'm perpetually questioning my purpose in life so i always welcome new ideas and thoughts! :) </p>
                <div className="social-buttons">
                    {
                        social.map((soc, ind) => (
                            <a href={soc.link} key={`soc-button-${ind}`} target="_blank">
                                <img src={soc.image}/>
                            </a>
                        ))
                    }
                </div>
            </div>
        )
    }

    public render(): JSX.Element {
        return(
            <div className="home">
                {this.renderIntro()}
                {this.renderLanding()}
                {this.renderExperience()}
                {this.renderProjects()}
                {this.renderContact()}
            </div>
        )
    }
}

const Bullet = (props: {text: string[]}): JSX.Element => (
    <div className="bullet">
        <div className="dot"/>
        <p>{
            props.text.map((phr, ind) => {
                if (phr.indexOf("http") > -1) {
                    const arr = phr.split("||");
                    return <a href={arr[0]} target="_blank">{arr[1]}</a>
                } else {
                    return phr;
                }
            })
        }</p>
    </div>
)