const experiences = [
    {
        company: "Airwallex",
        position: "Software Engineer Intern",
        timeline: "dec 2020 to june 2021",
        description: [
            "I contributed to a variety of frontend projects at ",
            "https://airwallex.com/||Airwallex",
            " including the new login and signup site catering to all platform users, and the redesign of the corporate site navigation bar.",
            " I currently hold the record of quickest PR made on the frontend team!"
        ],
        image: require("./images/Airwallex.png"),
    },
    {
        company: "BlackPine",
        position: "Technology Intern",
        timeline: "oct 2019 to sept 2020",
        description: [
            "I designed and implemented a variety of tools to improve operations efficiency at ",
            "https://bppe.com/||BlackPine",
            ".  Some workflows I built using Microsoft Power Automate helped reduce technology costs by USD6000/staff!"
        ],
        image: require("./images/BlackPine.png"),
    },
    {
        company: "Jubian",
        position: "Co-founder and Fullstack Engineer",
        timeline: "feb 2019 to aug 2019",
        description: [
            "I designed and developed the React Native mobile application for a cross-cultural grocery delivery platform, ",
            "https://jubian.co/||Jubian",
            ". The platform is currently deployed on the App Store in the US."
        ],
        image: require("./images/Jubian.png"),
    },
    {
        company: "Microsoft",
        position: "Software Engineer Intern",
        timeline: "june to aug 2019",
        description: [
            "I worked in a team of 7 ",
            "https://www.microsoft.com/en-us/garage/||Microsoft Garage",
            " interns to build a web application that teaches low-vision users how to use SmartFonts, alternate alphabets developed by ",
            "https://www.microsoft.com/en-us/research/||Microsoft Research",
            "."
        ],
        image: require("./images/Microsoft.png"),
    },
    {
        company: "MIT Media Lab",
        position: "Undergraduate Researcher",
        timeline: "sept 2018 to may 2019",
        description: [
            "I developed a tutorial feature in a child-robot literacy learning game and analyzed data on ",
            "https://www.media.mit.edu/projects/talking-machines-democratizing-the-design-of-voice-based-agents-for-the-home/overview/||user preferences on voice-based agents",
            ". I also helped develop ",
            "http://pipmothersill.com/index.php?/design-tools/looking-sideways-exploration-tool/||Sideways",
            ", an online exploration tool designed to provoke unexpected inspiration."
        ],
        image: require("./images/ML.png"),
    },
]

export default experiences;